






import { Component } from 'vue-property-decorator'

import { AbstractModuleUi } from '../../abstract/ui'

import { ArticlesTeaserModule } from '../ArticlesTeaser.contracts'
import { ArticlesTeaserVersion, articlesTeaserVersionRegistry } from '../ArticlesTeaser.config'
import { AsyncComponent } from 'vue'
import { logger } from '../../../support'

/**
 * Container component for the `ArticlesTeaserModuleUi`.
 *
 * @author Kuba Fogel <kuba.fogel@movecloser.pl>
 */
@Component<ArticlesTeaserModuleUi>({
  name: 'ArticlesTeaserModuleUi',
})
export class ArticlesTeaserModuleUi extends AbstractModuleUi<ArticlesTeaserModule> {
  public get component (): AsyncComponent | undefined {
    const component = articlesTeaserVersionRegistry[this.version]

    if (typeof component === 'undefined') {
      logger(
        `ArticlesTeaserModuleUi.ui.component(): There's no Vue component associated with the [${this.data.version}] ArticlesTeaserModuleUi!`,
        'error'
      )
      return
    }

    return component
  }

  public get version () {
    if (!this.data.version) {
      return ArticlesTeaserVersion.Default
    }

    return this.data.version as ArticlesTeaserVersion
  }
}

export default ArticlesTeaserModuleUi
